import React, { Fragment } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulbOn } from "@fortawesome/pro-light-svg-icons/faLightbulbOn";

export default function Faq() {
  return (
    <Fragment>
      <SEO title="Frequently Asked Questions" />
      <Layout fluid={false}>
        <h1>FAQ</h1>
        <div><FontAwesomeIcon icon={faLightbulbOn} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <h2>How does the pricing work?</h2>
        <p>
          <i>Pricing is based on volume.  You will be charged by how much your items will take up in a truck.  Some other fees will be incurred if your items are very heavy and/or the difficulty level of loading your items.  </i>
        </p>
        <h2>Should I get a dumpster or junk removal?</h2>
        <p><i>Do you have the ability and desire to do some work yourself?  If so, a dumpster may be the option you choose.  A full service junk removal company will do all the loading and lifting of your items into their truck and will be out of your hair typically in less than an hour.  So no bulky dumpster will be left on site taking up valuable space.</i></p>
        <h2>What if I have more stuff than the truck can hold?</h2>
<p><i>Don’t worry about that.  It happens all the time.  The truck can go to the closest disposal facility and return to load up the rest of your items.  You will only be charged for the volume your items take up in the truck.</i></p>

<h2>What items do you take?</h2>
<p><i>We take everything except hazardous material (Ex: paint, gas, oil, asbestos, pesticides, etc.), medical waste, and tires.  We take furniture like mattresses, appliances like refrigerators, and electronic waste like televisions.</i></p>

<h2>How much time do I need to give?</h2>
<p><i>Smaller loads average 5 – 15 minutes, while medium loads take about 15-45 minutes.  If you have a large, full load, plan on at least an hour.</i></p>

<h2>How do I pay?</h2>
<p><i>Payment is made at time of booking for the dumpster rental with any major debit or credit card.  Full service junk removal is paid for at completion of the job.  Considering that the price will fluctuate based on volume and other factors such as weight and ease of loading, it behooves us to wait on payment till job is finished and price is finalized.  We will of course provide you with an estimate before any work begins.  Payments for this service can be made with cash, check or credit/debit cards…whichever is easiest for you.</i></p>

<h2>Do I have to have everything in a pile?</h2>
<p><i>No, but it certainly makes it easier and quicker for us.  However, that is what you are paying us to do.  We will retrieve the items wherever you may have them.  We have gone to countless attics, crawlspaces, and basements.  Sometimes it is much easier for our crew to get items than if you were to spend the time and effort yourself.</i></p>

<h2>Can you go inside the residence?</h2>
<p>Yes we can certainly go inside your residence to retrieve items.  With the current situation caused by the pandemic, every crew member is required to wear a mask and gloves at all times.</p>

<h2>What is your COVID-19 policy?</h2>
<p><i>Our crewmembers are required to wear masks while inside the work trucks with other workers as well as the time spend at a residence doing work.  They are also required to wear gloves.  It is extremely important for our crewmembers to take the necessary precautions for their safety as well as the safety of others.  Hand sanitizer is in every truck and used frequently throughout the day.</i></p>

<h2>What are your dumpster sizes?</h2>
<p><i>We provide driveway safe 10, 15, and 20 cubic yard dumpsters.</i></p>

<h2>How much weight can I put in the dumpster without an extra fee?</h2>
<p><i>The weight limit for the 10 cubic yard dumpster is 3,000lbs.</i> <br />
<i>The weight limit for the 15 cubic yard dumpster is 4,000lbs.</i> <br />
<i>The weight limit for the 20 cubic yard dumpster is 6,000lbs.</i> <br />
	<i>The fee for extra weight is charged at $80/ton(2000lbs).</i><br />
  </p>

<h2>How do I schedule a pick up of the dumpster?</h2>
<p><i>Contact the number on the side of the dumpster when you are ready.  Typically, the dumpster should be picked up within 48hrs.</i></p>

      </Layout>
    </Fragment>
  )
}